<template>
  <menu>
    <nav v-if="info">
      <ul id="main-menu">
        <div
          id="site-logo"
          class="logo"
        >
          <router-link to="/">
            <img
              v-if="$route.path == '/pilotgalleriet'"
              src="../../assets/images/nitja-pilotgalleriet-500px.svg"
              alt="Nitja senter for samtidskunst logo"
            >
            <img
              v-else-if="$route.path == '/kunstvisitten'"
              src="../../assets/images/nitja-kunstvisitten-500px.svg"
              alt="Nitja senter for samtidskunst logo"
            >
            <img
              v-else-if="$route.path == '/nitja-kids'"
              src="../../assets/images/nitja-kids-500px.svg"
              alt="Nitja senter for samtidskunst logo"
            >
            <img
              v-else-if="$route.path == '/nitja-ung'"
              src="../../assets/images/nitja-ung-500px.svg"
              alt="Nitja senter for samtidskunst logo"
            >
            <img
              v-else-if="$route.path == '/nitja-online'"
              src="../../assets/images/nitja-online-500px.svg"
              alt="Nitja senter for samtidskunst logo"
            >
            <img
              v-else
              src="../../assets/images/nitja-mainlogo-500px.svg"
              alt="Nitja senter for samtidskunst logo"
            >
          </router-link>
        </div>
    
        <li>
          <router-link
            to="/"
            exact
          >
            {{ $t("nav.home") }}
          </router-link>
        </li>
    
        <li>
          <router-link
            to="/current-upcoming"
            :class="{ 'router-link-exact-active': isExhibitions}"
          >
            <span
              id="no-mobile-toggle"
              @click="toggleElement"
            >
              {{ $t("nav.exhibitions") }}
              <i
                id="toggler"
                class="arrow"
                :class="[ {up: isExhibitions == true}, {down: isExhibitions == false}]"
                @click="toggleElement"
              />
            </span>
          </router-link>

          <!-- <router-link to="/exhibitions">
                        <span @click="toggleElement" id="no-mobile-toggle">{{ $t("nav.exhibitions") }}<i @click="toggleElement" id="toggler" class="arrow down"></i></span>
                    </router-link> -->

          <ul
            id="dropdown"
            class="submenu"
            :class="{ opened: isExhibitions, 'router-link-exact-active': isExhibitions}"
          >
            <li>
              <router-link to="/current-upcoming">
                <span>{{ $t("nav.currentUpcoming") }}</span>
              </router-link>
            </li>
            <li>
              <router-link to="/archive">
                <span>{{ $t("nav.archive") }}</span>
              </router-link>
            </li>
            <li>
              <router-link to="/artists">
                <span>{{ $t("nav.artists") }}</span>
              </router-link>
            </li>
            <li>
              <router-link to="/nitja-online">
                <span>{{ $t("nav.nitjaOnline") }}</span>
              </router-link>
            </li>
          </ul>
        </li>

        <li>
          <router-link
            to="/pilotgalleriet"
            data-page-id="pilotgalleriet"
          >
            <span>{{ $t("nav.pilotgalleriet") }}</span>
          </router-link>
        </li>
    
        <li>
          <router-link
            to="/kunstvisitten"
            data-page-id="kunstvisitten"
          >
            <span>{{ $t("nav.kunstvisitten") }}</span>
          </router-link>
        </li>

        <li>
          <span
            class="fake-link"
            :class="{ 'router-link-exact-active': isActivities}"
          >
            <span
              id="no-mobile-toggle"
              @click="toggleElement"
            >
              {{ $t("nav.activities") }}
              <i
                id="toggler"
                class="arrow"
                :class="[ {up: isActivities == true}, {down: isActivities == false}]"
                @click="toggleElement"
              />
            </span>
          </span>
          <ul
            id="dropdown"
            class="submenu"
            :class="{ opened: isActivities}"
          >
            <li>
              <router-link to="/activities/adults">
                <span>{{ $t("activities.btnArtistic") }}</span>
              </router-link>
            </li>
            <li>
              <router-link to="/activities/children">
                <span>{{ $t("activities.btnKids") }}</span>
              </router-link>
            </li>
          </ul>
        </li>
    
        <li>
          <router-link to="/news">
            <span>{{ $t("nav.news") }}</span>
          </router-link>
        </li>
    
        <li>
          <router-link
            to="/information/about"
            :class="{ 'router-link-exact-active': isInformation}"
          >
            <span
              id="no-mobile-toggle"
              @click="toggleElement"
            >
              {{ $t("nav.information") }}
              <i
                id="toggler"
                class="arrow"
                :class="[ {up: isInformation == true}, {down: isInformation == false}]"
                @click="toggleElement"
              />
            </span>
          </router-link>
          <ul
            id="dropdown"
            class="submenu"
            :class="{ opened: isInformation}"
          >
            <li>
              <router-link to="/information/tickets">
                <span>{{ $t("nav.tickets") }}</span>
              </router-link>
            </li>
            <li>
              <router-link to="/information/contact">
                <span>{{ $t("nav.contact") }}</span>
              </router-link>
            </li>
            <li>
              <router-link to="/information/press">
                <span>{{ $t("nav.press") }}</span>
              </router-link>
            </li>
            <li>
              <router-link to="/information/about">
                <span>{{ $t("nav.about") }}</span>
              </router-link>
            </li>
            <li>
              <router-link to="/information/space-rental">
                <span>{{ $t("nav.renting") }}</span>
              </router-link>
            </li>
            <!-- <li>
              <router-link to="/information/about-name">
                <span>{{ $t("nav.aboutName") }}</span>
              </router-link>
            </li> -->
            <li>
              <router-link to="/information/ownership">
                <span>{{ $t("nav.ownership") }}</span>
              </router-link>
            </li>
            <li>
              <router-link to="/information/design">
                <span>{{ $t("nav.design") }}</span>
              </router-link>
            </li>
            <li>
              <router-link to="/information/public-space">
                <span>{{ $t("nav.publicSpace") }}</span>
              </router-link>
            </li>
            <li>
              <router-link to="/information/open-call">
                <span>{{ $t("nav.openCall") }}</span>
              </router-link>
            </li>
            <li>
              <router-link to="/information/history">
                <span>{{ $t("nav.history") }}</span>
              </router-link>
            </li>
          </ul>
        </li>
    
        <li>
          <router-link to="/cafe">
            <span>{{ $t("nav.cafe") }}</span>
          </router-link>
        </li>

        <li>
          <router-link to="/store">
            <span>{{ $t("nav.store") }}</span>
          </router-link>
        </li>

        <li>
          <router-link to="/lounge">
            <span>{{ $t("nav.lounge") }}</span>
          </router-link>
        </li>

        <li>
          <span
            class="fake-link"
            :class="{ 'router-link-exact-active': isKidsUng}"
          >
            <span
              id="no-mobile-toggle"
              @click="toggleElement"
            >
              Nitja Kids / Nitja Ung
              <i
                id="toggler"
                class="arrow"
                :class="[ {up: isKidsUng == true}, {down: isKidsUng == false}]"
                @click="toggleElement"
              />
            </span>
          </span>
          <ul
            id="dropdown"
            class="submenu"
          >
            <li>
              <router-link to="/nitja-ung">
                <span>{{ $t("nav.nitjaUng") }}</span>
              </router-link>
            </li>

            <li>
              <router-link
                to="/nitja-kids"
                data-page-id="nitja-kids"
              >
                <span>{{ $t("nav.nitjaKids") }}</span>
              </router-link>
            </li>
          </ul>
        </li>
    
        <li>
          <router-link to="/nitja-friends">
            <span>{{ $t("nav.nitjaFriends") }}</span>
          </router-link>
        </li>
                
        <li>
          <router-link to="/publications">
            <span>{{ $t("nav.publications") }}</span>
          </router-link>
        </li>

        <li>
          <router-link to="/collaborators">
            <span>{{ $t("nav.collaborators") }}</span>
          </router-link>
        </li>

        <li>
          <p class="fake-link">
            <span
              id="no-mobile-toggle"
              @click="toggleElement"
            >{{ $t("nav.visit") }}<i
              id="toggler"
              class="arrow up"
              @click="toggleElement"
            /></span>
          </p>
          <div
            id="dropdown"
            class="visit-box opened"
          >
            <div class="visit-box--top">
              <p>Kirkegata 10A</p>
              <p>2000, Lillestrøm</p>
              <p v-if="$i18n.locale === 'en'">
                Norway
              </p>
              <p v-else>
                Norge
              </p>
            </div>
    
            <div class="visit-box--middle">
              <p
                v-show="info.openinghours"
                id="museum-open"
                ref="museumBtn"
                class="rounded-btn--green"
              >
                <span>{{ isOpen }}</span>
              </p>
            </div>
    
            <div class="visit-box--bottom">
              <ul class="timetable">
                <li
                  v-for="day in info.openinghours"
                  :key="day.day_en"
                >
                  <span v-if="$i18n.locale === 'en'">{{ day.day_en }}</span>
                  <span v-else>{{ day.day_no }}</span>
                  <span v-if="day.opening">{{ day.opening | formatTime }}–{{ day.closing | formatTime }}</span>
                  <span v-else>{{ $t("info.closed") }}</span>
                </li>
              </ul>
            </div>
          </div>
        </li>
    
        <LocaleComponent />
      </ul>
    
      <ul id="secondary-menu">
        <li>
          <router-link to="/imprint">
            {{ $t("nav.imprint") }}
          </router-link>
        </li>
      </ul>
    </nav>
  </menu>
</template>

<script>
import {format} from 'date-fns';
import LocaleComponent from '../LocaleComponent.vue';
import gql from "graphql-tag";

const getInfo = gql `
    query Info {
        info {
            openinghours {
                day_no
                day_en
                opening
                closing
            }
        }
    }
`;

export default {
    name: "BaseMenu",
    components: {
        LocaleComponent
    },
    data() {
        return {
            museumOpen: true,
            isInformation: '',
            isActivities: '',
            isKidsUng: '',
            isExhibitions: '',
            localeNO: "NO",
            localeEN: "EN",
        }
    },
    apollo: {
        info: {
            query: getInfo
        }
    },
    computed: {
        isOpen() {
            const week = this.info.openinghours;
            const thisDay = format(new Date(), 'iiii');
            let thisTime = format(new Date(), 'HH:mm');
            let result = '';

            week.forEach(function(day) {
                if (day['day_en'] == thisDay) {
                    if (day['opening'] < thisTime && day['closing'] > thisTime) {
                        result = true;
                    } else {
                        result = false;
                    }
                }
            });

            this.$nextTick(() => {
                const museumBtn = document.querySelector('#museum-open');
                if (result == false) {
                    museumBtn.classList.remove('rounded-btn--green');
                    museumBtn.classList.add('rounded-btn--red');
                } else {
                    museumBtn.classList.remove('rounded-btn--red');
                    museumBtn.classList.add('rounded-btn--green');
                }
            });

            if (result == false) {
                return this.$t('buttons.closed')
            } else {
                return this.$t('buttons.open')
            }
        }
    },
    watch: {
        $route (to){
            if (to['path'].includes('/information')) {
                this.isExhibitions = false
                this.isActivities = false
                this.isInformation = true
                this.isKidsUng = false
            } else if (to['path'].includes('/archive')) {
                this.isExhibitions = true
                this.isActivities = false
                this.isInformation = false
                this.isKidsUng = false
            } else if (to['path'].includes('/artists')) {
                this.isExhibitions = true
                this.isActivities = false
                this.isInformation = false
                this.isKidsUng = false
            } else if (to['path'].includes('/exhibitions')) {
                this.isExhibitions = true
                this.isActivities = false
                this.isInformation = false
                this.isKidsUng = false
            } else if (to['path'].includes('/nitja-online')) {
                this.isExhibitions = true
                this.isActivities = false
                this.isInformation = false
                this.isKidsUng = false
            } else if (to['path'].includes('/activities')) {
                this.isExhibitions = false
                this.isActivities = true
                this.isInformation = false
                this.isKidsUng = false
            } else if (to['path'].includes('/nitja-kids')) {
                this.isActivities = false
                this.isInformation = false
                this.isExhibitions = false
                this.isKidsUng = true
            } else if (to['path'].includes('/nitja-ung')) {
                this.isActivities = false
                this.isInformation = false
                this.isExhibitions = false
                this.isKidsUng = true
            } else {
                this.isInformation = false
                this.isExhibitions = false
                this.isActivities = false
                this.isKidsUng = false
            }
        }
    },
    methods: {
        checkDropdowns(toggler, dropdown) {
            const allDropdown = document.querySelectorAll('#dropdown')
            const allToggler = document.querySelectorAll('#toggler')
            allDropdown.forEach(function(i) {
                if ( i.classList.contains('opened')) {
                    i.classList.remove('opened');
                }
            })
            allToggler.forEach(function(i) {
                i.classList.remove('up');
                i.classList.add('down');
            })
            toggler.classList.remove('down');
            toggler.classList.add('up');
            dropdown.classList.add('opened');
        },
        toggleElement: function(event) {
            if (event) {
                const dropdown = event.target.parentNode.nextSibling;
                const toggler = event.target.childNodes[1];

                if (dropdown.classList.contains('opened')) {
                    dropdown.classList.remove('opened');
                    toggler.classList.remove('up');
                    toggler.classList.add('down');
                } else {
                    this.checkDropdowns(toggler, dropdown);
                }
            } 
        }
    }
};
</script>