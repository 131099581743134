import normalizeUrl from 'normalize-url'
import { format, parseISO } from 'date-fns'
import enGB from 'date-fns/locale/en-GB'
import nb from 'date-fns/locale/nb'

export default {
    install(Vue) {
        Vue.filter('cleanUrl', function (value) {
            return normalizeUrl(value);
        })
        Vue.filter('splitUrl', function (value) {
            return normalizeUrl(value, {stripProtocol: true});
        })
        Vue.filter('splitIG', function (value) {
            return '@' + normalizeUrl(value, {stripProtocol: true}).replace('instagram.com/', '');
        })
        Vue.filter('formatDateFull', function (value) {
            const isoValue = parseISO(value, new Date());
            return format(isoValue, "dd.MM.yyyy");
        })
        Vue.filter('formatDateYear', function (value) {
            const isoValue = parseISO(value, new Date());
            return format(isoValue, "yyyy");
        })
        Vue.filter('formatDateTimeFull', function (value) {
            const isoValue = parseISO(value, new Date());
            return format(isoValue, "dd.MM.yyyy HH:mm");
        })
        Vue.filter('formatNBday', function (value) {
            const isoValue = parseISO(value, new Date());
            return format(isoValue, "iiii", {locale: nb});
        })
        Vue.filter('formatENday', function (value) {
            const isoValue = parseISO(value, new Date());
            return format(isoValue, "iiii", {locale: enGB});
        })
        Vue.filter('formatTime', function (value) {
            let date = value;
            if (date) {
                const splittedString = date.split(":");
                date = splittedString.slice(0, -1).join(':');
            }
            return date;
        })
    }
}

 