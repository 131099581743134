<template>
  <div
    id="cc-message"
    class="message-bar bar-bot message--cc"
  >
    <div class="bar--msg">
      <p>{{ $t("cc.message") }}</p>
      <button
        id="cc-allow"
        class="square-btn--green"
        type="button"
        :value="$t('cc.button')"
      >
        <span>{{ $t('cc.button') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';

export default {
    mounted() {
        // Cookies.remove('allow-lang-storage');
        const ccBar = document.querySelector("#cc-message");
        const ccAllow = document.getElementById("cc-allow");
        if (Cookies.get('allow-lang-storage') == 1) {
            ccBar.style.display = "none";
        } else {
            ccBar.style.display = "flex";
            ccAllow.addEventListener('click', function() {
                Cookies.set('allow-lang-storage', 1, { expires: 3 })
                ccBar.classList.add('ccslide');
                ccBar.ontransitionend = () => {
                    ccBar.style.display = "none";
                };
            })
        }
    }
}
</script>
