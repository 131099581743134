import Vue from "vue"
import VueApollo from "vue-apollo"
import VueMeta from "vue-meta"
import VueYoutube from "vue-youtube"
import apolloClient from "./vue-apollo"
import i18n from "./services/internationalization/index"
import router from "./services/router/index"
// import store from "./store";
import customFilters from "./services/filters/index"
import App from "./App.vue"
 
Vue.config.productionTip = false;

Vue.use(customFilters);
Vue.use(VueApollo);
Vue.use(VueYoutube)
Vue.use(VueMeta, {
    // optional pluginOptions
    refreshOnceOnNavigation: true
})

const apolloProvider = new VueApollo({
    defaultClient: apolloClient
});

new Vue({
    i18n,
    apolloProvider,
    router,
    render: h => h(App),
}).$mount("#app");