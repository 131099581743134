<template>
  <div
    id="app"
    class="grid-container"
  >
    <BaseMenu />

    <main>
      <BaseGlobalMessage />

      <!-- <BaseNotificationMessage :staticMessageEN="this.staticMessageEN" :messageType="this.messageType" /> -->
            
      <div
        id="site-logo-mobile"
        class="logo mobile-only"
      >
        <router-link to="/">
          <img
            v-if="$route.path == '/pilotgalleriet'"
            src="./assets/images/nitja-pilotgalleriet-500px.svg"
            alt="Nitja senter for samtidskunst logo"
          >
          <img
            v-else-if="$route.path == '/kunstvisitten'"
            src="./assets/images/nitja-kunstvisitten-500px.svg"
            alt="Nitja senter for samtidskunst logo"
          >
          <img
            v-else
            src="./assets/images/nitja-mainlogo-500px.svg"
            alt="Nitja senter for samtidskunst"
          >
        </router-link>
    
        <div
          id="menu-toggler"
          @click="toggleMenu"
        >
          <i class="arrow right" />
        </div>
      </div>
    
      <router-view :key="$route.fullPath" />
    
      <BaseCC />
    </main>
  </div>
</template>

<script>
import BaseMenu from "./components/base/BaseMenu"
import BaseCC from "./components/base/BaseCC"
import BaseGlobalMessage from './components/base/BaseGlobalMessage'

export default {
    name: 'Nitja',
    components: {
        BaseMenu,
        BaseCC,
        BaseGlobalMessage,
    },
    data: function() {
        return {
            api_url: process.env.VUE_APP_STRAPI_API_URL,
        };
    },
    metaInfo() {
        return { 
            title: this.$t("nav.home"),
            titleTemplate: '%s',
            meta: [
                { charset: 'utf-8' },
                { name: 'viewport', content: 'width=device-width, initial-scale=1' },
                { name: 'robots', content: 'index,follow' },
                { name: 'description', content: 'Nitja senter for samtidskunst ligger sentralt til i Lillestrøm, kun ti minutter fra Oslo S og Oslo lufthavn. Senteret har spesialisert seg på visning og formidling av norsk og internasjonal samtidskunst med fokus på yngre kunstnere.' },
                { name: 'keywords', content: 'Kunst, Kunstgalleri, Galleri, Akershus Kunstsenter, Pilotgalleriet, Kunstvisitten, Samtidskunst, Kunstbutikk, Kafe, Kunsthåndverk, Lillestrøm, Kunstnersenter, Utstilling, Arrangementer, Møterom i Lillestrøm, Kunstlounge, Contemporary art Oslo, Art Oslo, Galleries Oslo, Hva skjer Lillestrøm, Hva skjer Oslo, Barneaktiviteter' },
                { property: 'og:site_name', content: 'Nitja senter for samtidskunst' },
                { property: 'og:type', content: 'website' },    
                { property: 'og:image', content: '/nsfs-openGraph.jpg', vmid: 'og:image' },    
                { property: 'og:image:width', content: '1200'},    
                { property: 'og:image:height', content: '630'},    
                { property: 'og:title', content: "Nitja senter for samtidskunst", vmid: "og:title" },
                { property: 'og:description', content: "Nitja senter for samtidskunst ligger sentralt til i Lillestrøm, kun ti minutter fra Oslo S og Oslo lufthavn. Senteret har spesialisert seg på visning og formidling av norsk og internasjonal samtidskunst med fokus på yngre kunstnere. Nitja byr på spennende og opplevelsesrike utstillinger med billedkunst og kunsthåndverk, samt aktiviteter for barn i eget aktivitetsrom.", vmid: "og:description" },
                { property: 'twitter:card', content: '/nsfs-openGraph.jpg', vmid: 'twitter:card' },
                { property: 'twitter:site', content: '@nitjakunst', vmid: 'twitter:site' },
                { property: 'twitter:title', content: 'Nitja senter for samtidskunst', vmid: 'twitter:title' },
                { property: 'twitter:description', content: "Nitja senter for samtidskunst ligger sentralt til i Lillestrøm, kun ti minutter fra Oslo S og Oslo lufthavn. Senteret har spesialisert seg på visning og formidling av norsk og internasjonal samtidskunst med fokus på yngre kunstnere. Nitja byr på spennende og opplevelsesrike utstillinger med billedkunst og kunsthåndverk, samt aktiviteter for barn i eget aktivitetsrom.", vmid: 'twitter:description' },
            ]
        }
    },
    methods: {
        toggleMenu() {
            const nav = document.querySelector('nav');
            const body = document.querySelector('body');
            const toggler = event.target.firstChild
            if (event) {
                nav.classList.toggle('menu-active');
                if (nav.classList.contains("menu-active")) {
                    body.style.overflowY = "hidden";
                    nav.style.overflowY = "scroll";
                    toggler.classList.remove('arrow');
                    toggler.classList.remove('right');
                    toggler.classList.add('close');
                } else {
                    toggler.classList.remove('close');
                    toggler.classList.add('arrow');
                    toggler.classList.add('right');
                    body.style.overflowY = "scroll";
                    nav.style.overflowY = "hidden";
                }
                nav.addEventListener('click', function(event) {
                    var myID = event.target.id;
                    if (myID != 'no-mobile-toggle') {
                        nav.classList.remove('menu-active');
                        body.style.overflowY = "scroll";
                        nav.style.overflowY = "hidden";
                        toggler.classList.remove('close');
                        toggler.classList.add('arrow');
                        toggler.classList.add('right');
                    }
                })
            }
        }
    },

}
</script>

<style lang="scss">
    @import 'src/assets/styles/main.scss';
</style>



