<template>
  <ul id="language-selector">
    <li :class="[$i18n.locale === 'nb' ? 'active' : 'lang-btn']">
      <label>
        <input
          v-model="$i18n.locale"
          type="radio"
          value="nb"
          @click.self="activeLang"
        >
        <span for="radio">Norsk</span>
      </label>
    </li>
    <li :class="[$i18n.locale === 'en' ? 'active' : 'lang-btn']">
      <label>
        <input
          v-model="$i18n.locale"
          type="radio"
          value="en"
          @click.self="activeLang"
        >
        <span for="radio">English</span>
      </label>
    </li>
  </ul>
</template>

<script>
import Cookies from 'js-cookie';

export default {
    methods: {
        data: function() {
            return {
                activeLang: this.$i18n.locale,
            }
        },
        created() {
        },
        activeLang: function(event) {
            if (event) {
                // const thisLangBtn = event.target.parentNode.parentNode;
                // const otherLangBtn = thisLangBtn.nextSibling || thisLangBtn.previousSibling;
                // thisLangBtn.classList.remove('lang-btn');
                // thisLangBtn.classList.add('active');
                // otherLangBtn.classList.remove('active');
                // otherLangBtn.classList.add('lang-btn');
                Cookies.set('user-pref-lang', event.target.value)
            }
        }
    }
}
</script>