<template>
  <div
    v-if="info && info.global_message.message_boolean == true"
    id="global-message"
    class="message-bar messageslide-down bar-top"
    :class="{'message--error': info.global_message.message_type == 'error', 'message--notification' : info.global_message.message_type == 'notification'}"
  >
    <div class="bar--msg">
      <p>
        <span v-if="$i18n.locale === 'nb'">{{ info.global_message.message_no }}</span>
        <span v-else>{{ info.global_message.message_en }}</span>
        <a
          v-if="info.global_message.url && $i18n.locale === 'nb'"
          :href="info.global_message.url | cleanUrl"
          target="_blank"
          rel="noreferrer noopener"
          class="metatext"
        >
          <span>{{ info.global_message.button_no }}</span>
          <svgExternal />
        </a>
        <a
          v-else-if="info.global_message.url"
          :href="info.global_message.url | cleanUrl"
          target="_blank"
          rel="noreferrer noopener"
          class="metatext"
        >
          <span>{{ info.global_message.button_en }}</span>
          <svgExternal />
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import svgExternal from '@/components/base/svgExternal'
import gql from 'graphql-tag'

const getInfo = gql `
    query Info {
        info {
            global_message {
                message_boolean
                message_type
                message_no
                message_en
                button_no
                button_en
                url
            }
        }
    }
`;

export default {
    apollo: {
        info: {
            query: getInfo
        }
    },
    components: {
      svgExternal
    },
    data: function() {
        return {
            // messageType: this.info.global_message.message_type
        }
    },
    created() {
        function messageBarInit() {
            const messageBar = document.getElementById("global-message");
            // messageBar.classList.remove('messageslide-up');
            // messageBar.classList.add('messageslide-down');
            if (messageBar) {
                messageBar.addEventListener('click', function() {
                    messageBar.classList.remove('messageslide-down');
                    messageBar.classList.add('messageslide-up');
                    messageBar.ontransitionend = () => {
                        messageBar.style.display = "none";
                    };
                })
            }
        }
        setTimeout(messageBarInit, 2000);
    },
}
</script>