import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

Vue.use(Router)
Vue.use(Meta)

const router = new Router({
    mode: "history",
    base: window.location.pathName,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView();
    },
    routes: [
        {
            path: "/",
            component: () => import( /* webpackChunkName: "home" */ "@/views/LandingPage.vue"),
            name: "Home",
        },
        {
            path: "/maintenance",
            component: () => import( /* webpackChunkName: "extra" */ "@/views/core/MaintenancePage.vue"),
            name: "Maintenance",
        },
        {
            path: "/exhibitions",
            component: () => import( /* webpackChunkName: "exhibitions" */ "@/containers/Exhibitions.vue"),
            name: "Exhibitions"
        },
        {
            path: "/exhibitions/:slug",
            component: () => import( /* webpackChunkName: "exhibition" */ "@/containers/Exhibition.vue"),
            name: "Exhibition"
        },
        {
            path: "/current-upcoming",
            component: () => import( /* webpackChunkName: "exhibitions" */ "@/views/CurrentUpcomingPage.vue"),
            name: "Current and Upcoming"
        },
        {
            path: "/archive",
            component: () => import( /* webpackChunkName: "exhibitions" */ "@/views/ArchivePage.vue"),
            name: "Archive"
        },
        {
            path: "/artists",
            component: () => import( /* webpackChunkName: "exhibitions" */ "@/containers/Artists.vue"),
            name: "Artists"
        },
        {
            path: "/nitja-online",
            component: () => import( /* webpackChunkName: "exhibitions" */ "@/containers/Interviews.vue"),
            name: "Nitja Online"
        },
        {
            path: "/nitja-online/:slug",
            component: () => import( /* webpackChunkName: "exhibitions" */ "@/containers/Interview.vue"),
            name: "Interview"
        },
        {
            path: "/pilotgalleriet",
            component: () => import( /* webpackChunkName: "singlepages" */ "@/views/PilotgallerietPage.vue"),
            name: "Pilotgalleriet"
        },
        {
            path: "/kunstvisitten",
            component: () => import( /* webpackChunkName: "singlepages" */ "@/views/KunstvisittenPage.vue"),
            name: "Kunstvisitten"
        },
        {
            path: "/activities",
            component: () => import( /* webpackChunkName: "activities" */ "@/containers/Activities.vue"),
            name: "Activities"
        },
        {
            path: "/activities/adults",
            component: () => import( /* webpackChunkName: "activities" */ "@/views/ActivitiesAdults.vue"),
            name: "Activities adults"
        },
        {
            path: "/activities/children",
            component: () => import( /* webpackChunkName: "activities" */ "@/views/ActivitiesChildren.vue"),
            name: "Activities children"
        },
        {
            path: "/activities/:slug",
            component: () => import( /* webpackChunkName: "activities" */ "@/containers/Activity.vue"),
            name: "Activity"
        },
        {
            path: "/news",
            component: () => import( /* webpackChunkName: "posts" */ "@/containers/Posts.vue"),
            name: "News"
        },
        {
            path: "/news/:slug",
            component: () => import( /* webpackChunkName: "posts" */ "@/containers/Post.vue"),
            name: "Post"
        },
        {
            path: "/information/ownership",
            component: () => import( /* webpackChunkName: "information" */ "@/views/information/InformationPage.vue"),
            name: "Ownership"
        },
        {
            path: "/information/tickets",
            component: () => import( /* webpackChunkName: "information" */ "@/views/information/TicketsPage.vue"),
            name: "Tickets"
        },
        {
            path: "/information/contact",
            component: () => import( /* webpackChunkName: "information" */ "@/views/information/ContactPage.vue"),
            name: "Contact"
        },
        {
            path: "/information/about",
            component: () => import( /* webpackChunkName: "information" */ "@/views/information/AboutPage.vue"),
            name: "About"
        },
        {
            path: "/information/press",
            component: () => import( /* webpackChunkName: "information" */ "@/views/information/PressPage.vue"),
            name: "Press"
        },
        {
            path: "/information/history",
            component: () => import( /* webpackChunkName: "information" */ "@/views/information/HistoryPage.vue"),
            name: "History"
        },
        {
            path: "/information/space-rental",
            component: () => import( /* webpackChunkName: "information" */ "@/views/information/SpaceRentalPage.vue"),
            name: "Space Rental"
        },
        {
            path: "/information/public-space",
            component: () => import( /* webpackChunkName: "information" */ "@/views/information/PublicSpacePage.vue"),
            name: "Public Space"
        },
        {
            path: "/information/design",
            component: () => import( /* webpackChunkName: "information" */ "@/views/information/ArchitectureDesignPage.vue"),
            name: "Design"
        },
        {
            path: "/information/about-name",
            component: () => import( /* webpackChunkName: "information" */ "@/views/information/AboutName.vue"),
            name: "About Name"
        },
        {
            path: "/information/open-call",
            component: () => import( /* webpackChunkName: "information" */ "@/views/information/OpenCallPage.vue"),
            name: "Open Calls"
        },
        {
            path: "/cafe",
            component: () => import( /* webpackChunkName: "singlepages" */ "@/views/CafePage.vue"),
            name: "Cafe"
        },
        {
            path: "/store",
            component: () => import( /* webpackChunkName: "singlepages" */ "@/views/StorePage.vue"),
            name: "Store"
        },
        {
            path: "/lounge",
            component: () => import( /* webpackChunkName: "singlepages" */ "@/views/LoungePage.vue"),
            name: "Art Lounge"
        },
        {
            path: "/nitja-ung",
            component: () => import( /* webpackChunkName: "singlepages" */ "@/views/NitjaUngPage.vue"),
            name: "Nitja Ung"
        },
        {
            path: "/nitja-kids",
            component: () => import( /* webpackChunkName: "singlepages" */ "@/views/NitjaKidsPage.vue"),
            name: "Nitja Kids"
        },
        {
            path: "/nitja-friends",
            component: () => import( /* webpackChunkName: "singlepages" */ "@/views/NitjaFriendsPage.vue"),
            name: "Nitja Friends"
        },
        {
            path: "/publications",
            component: () => import( /* webpackChunkName: "extra" */ "@/views/PublicationsPage.vue"),
            name: "Publications"
        },
        {
            path: "/collaborators",
            component: () => import( /* webpackChunkName: "extra" */ "@/views/CollaboratorsPage.vue"),
            name: "Collaborators"
        },
        {
            path: "/imprint",
            component: () => import( /* webpackChunkName: "extra" */ "@/views/ImprintPage.vue"),
            name: "Imprint"
        },
        {
            path: "/privacypolicy",
            component: () => import( /* webpackChunkName: "extra" */ "@/views/PrivacyPage.vue"),
            name: "Privacy Policy"
        },
        {
            path: "/visions",
            component: () => import( /* webpackChunkName: "extra" */ "@/views/VisionsPage.vue"),
            name: "Visions"
        },
        {
            path: '/404',
            component: () => import( /* webpackChunkName: "extra" */ "@/views/core/404.vue"),
        },
        {
            path: '*',
            redirect: '/404'
        }
    ]
})

const DEFAULT_LANG = "no";

router.afterEach(() => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.html = DEFAULT_LANG;
    });
});

export default router;